import React, { Component } from 'react'
import Layout from '../layout/Layout.jsx'
import Header from '../components/Header'
import Card from '../components/Card.jsx'
import SelectBar from '../components/SelectBar.jsx'
import MpList from '../data/MPList'

import '../styles/chart.scss'

import { withPrefix } from 'gatsby';

import { navigate } from 'gatsby';

class App extends Component {
  constructor(props) {
    super(props)
  }


  render() {
    return (
      <Layout>
        <div className="row">
          <div className="small-12 columns">
            <div className="loading-image-container">
              <img src={withPrefix('/img/peter_dutton.png')} className="loading-image" />
              <img src={withPrefix('/img/peter_dutton.png')} className="loading-image" />
              <img src={withPrefix('/img/peter_dutton.png')} className="loading-image" />
              <img src={withPrefix('/img/peter_dutton.png')} className="loading-image" />
              <img src={withPrefix('/img/peter_dutton.png')} className="loading-image" />
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}
export default App
